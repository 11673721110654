import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ActiveProjectPaginationEntries } from '../../routes/dashboard/dashboardMain'
import ActiveProjectsList from '../Active Project List/ActiveProjectsList'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store/appStore'
import { getProjectWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard'

const ActiveProjectsOverview = () => {
    const { id } = useParams()
  const dispatch = useDispatch<any>()
  const [tableSwitch, setTableSwitch] = useState("TASK")
  const {getProjectWiseAnalyticsStatus, projectWiseAnalytics} = useSelector((state: RootState) => state.dashboard)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [step, setStep] = useState(0);


//   const handleChangeToggle = (event: any, table: string,) => {
//     if (table !== null) {
//       setTableSwitch(table);
//       dispatch(getExpertAnalytics({ project_id: id, type: event.target.value, association_type: "Admin" }))
//     }
//   };

//   useEffect(() => {
//     // dispatch(getAnalytics({ project_id: "", type: "all_project" }))
//     dispatch(getMetricsAction({ association_type: "COMPANY" }))
//     dispatch(getPseudoNameWiseAnalyticsAction())
//     dispatch(getProjectWiseAnalyticsAction({ association_type: "COMPANY" }))
//     dispatch(getTaskWiseAnalyticsAction({ allocation_type: "REVIEW" }));
//     dispatch(getVendorWiseAnalyticsAction({ page: currentPage, pageSize: itemsPerPage as number, association_type: "VENDOR" }))

//   }, [dispatch])



  const handleChangeRowsPerPageActiveProject = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    dispatch(getProjectWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "COMPANY" }));
  };

  const handleOnChangeActiveProject = (value: number) => {
    setItemsPerPage(value)
  }
  const handleOnChangeActiveVendors = (value: number) => {
    setItemsPerPage(value)
  }
    return (
        <>
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', justifyContent: 'space-between' }} >
                <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>List of active projects</Typography>
                <ActiveProjectPaginationEntries
                    itemsPerPage={itemsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPageActiveProject}
                    handleOnChange={handleOnChangeActiveProject}
                />
            </div>
            <ActiveProjectsList
                data={projectWiseAnalytics}
                dataStatus={getProjectWiseAnalyticsStatus}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
            />
        </>
    )
}

export default ActiveProjectsOverview