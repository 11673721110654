import { Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InviteExpert from './experts/inviteExpert';
import UserAdminsList from './admins/UserAdminsList';
import UserVendorsList from './vendors/UserVendorsList';

interface UserTabOptionsProps {
  userTab: string;
}

const UserTabOptions: React.FC<UserTabOptionsProps> = ({ userTab }) => {
  const [alignment, setAlignment] = useState(userTab);
  useEffect(() => {
    if (userTab) {
      setAlignment(userTab);
    }
  }, [userTab]);

  return (
    <div
      style={{
          borderBottom: '1px solid',
          borderColor: '#E8EAEF',
          margin: '0 2px 2px 2px',
          padding: '0px, 24px, 0px, 24px'
      }}
    >

      {alignment === "expert" && (
        <InviteExpert />
      )}
      {alignment === "vendor" && (
        <UserVendorsList />
      )}
      {alignment === "admin" && (
        <UserAdminsList />
      )}
    </div>
  )
}

export default UserTabOptions