import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AnalyticsListTable from '../analytics/taskCompletedList'
import SnackbarNotification from '../snackbar/snackbarNotification'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from '../../redux/store/appStore'
import { getExpertAnalytics } from '../../redux/slices/analytics/experts'
import { getAnalytics } from '../../redux/slices/analytics'
import { STATUS } from '../../utils/status'
import VendorAnalyticsListTable from '../analytics/vendorAnalyticsList'


function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const VendorAnalytics = () => {
  const analytics = useSelector((state: RootState) => state.analytics.analytics.data)
  const getAnalyticsStatus = useSelector((state: RootState) => state.analytics.getStatus)
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const [tableSwitch, setTableSwitch] = useState("TASK")
  const association_type = useSelector((state: RootState) => state.projects.association_type);
  const { downloadAnalyticsByExpertStatus, analyticsExpertDownload } = useSelector((state: RootState) => state.analyticsExpert)
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleDownloadForTask = () => {
  //   dispatch(getExpertAnalyticsDownload({
  //     project_id: id,
  //     type: 'TASK',
  //     association_type: association_type,
  //     download: true
  //   }))
  //   handleMenuClose();
  // };

  // const handleDownloadForReview = () => {
  //   dispatch(getExpertAnalyticsDownload({
  //     project_id: id,
  //     type: 'REVIEW',
  //     association_type: association_type,
  //     download: true
  //   }))
  //   handleMenuClose();
  // };

  const handleChangeToggle = (event: any, table: string,) => {
    if (table !== null) {
      setTableSwitch(table);
      // navigate(`/project/${id}?tab=${newAlignment}`);
      dispatch(getExpertAnalytics({ project_id: id, type: event.target.value, association_type: association_type }))
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // useEffect(() => {
  //   if (downloadAnalyticsByExpertStatus === STATUS.SUCESS) {
  //     if (typeof analyticsExpertDownload == "string") {
  //       window.open(analyticsExpertDownload, '_blank')
  //     }
  //   }

  //   if (downloadAnalyticsByExpertStatus == STATUS.FAILED) {
  //     setSnackbarMessage('Couldn\'t Download File. Please Try Again.')
  //     setSnackbarSeverity('error')
  //     setSnackbarOpen(true)
  //   }

  //   return () => {
  //     if (downloadAnalyticsByExpertStatus == STATUS.SUCESS || downloadAnalyticsByExpertStatus == STATUS.FAILED) {
  //       dispatch(setDownloadAnalyticsByExpertStatus(STATUS.NOT_STARTED))
  //     }

  //   }
  // }, [downloadAnalyticsByExpertStatus])

  let backgrounds = [
    'linear-gradient(45deg, #42a5f5, #478ed1)',
    'linear-gradient(45deg, #ffa726, #fb8c00)',
    'linear-gradient(45deg, #66bb6a, #4caf50)'
  ]

  return (
    <>
      {association_type === 'COMPANY' &&
        (
          <>
            <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>Vendor Analytics</Typography>
            <VendorAnalyticsListTable />
          </>
        )
      }

      {/* <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      /> */}
    </>
  )
}

export default VendorAnalytics