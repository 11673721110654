import React, { useEffect, useState } from 'react'
import TaskCompletedList from './taskCompletedList'
import AnalyticsListTable from './expertAnalyticsList'
import { Paper, ToggleButton, SelectChangeEvent, ToggleButtonGroup, Typography, CircularProgress, Button, IconButton, Menu, MenuItem, Box, Tabs, Tab } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store/appStore'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getAnalytics } from '../../redux/slices/analytics'
import { getExpertAnalytics, getExpertAnalyticsDownload, setDownloadAnalyticsByExpertStatus } from '../../redux/slices/analytics/experts'
import VendorAnalyticsListTable from './vendorAnalyticsList'
import { AddButton } from '../button/button'
import { STATUS } from '../../utils/status'
import SnackbarNotification from '../snackbar/snackbarNotification'
import AnalyticsOverview from '../AnalyticsTabs/AnalyticsOverview'
import TaskByDate from '../AnalyticsTabs/TaskByDate'
import TaskByExperts from '../AnalyticsTabs/TaskByExperts'
import VendorAnalytics from '../AnalyticsTabs/VendorAnalytics'
// import { SearchBar } from '../inputField/textfield'
// import { getUpload } from '../../redux/slices/upload'
// import PaginationEntries from '../pagination/paginationEntries'


const AnalyticsMain = () => {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get('analyticsTab') || 'analyticsOverview';
  const [selectedTab, setSelectedTab] = useState<string>(initialTab);     
  console.log({initialTab})

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    console.log({newValue})
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.set('analyticsTab', newValue);
      return params;
    });
  };

   useEffect(() => {
      const urlTab = searchParams.get("analyticsTab");
      console.log({urlTab})
      if(urlTab && urlTab !== selectedTab){
        setSelectedTab(urlTab)
      }
    },[searchParams.toString()])

  return (
    <div>

      <div
        style={{
          backgroundColor: '#FFFFFF',
          border: '1px solid lightgrey',
          borderRadius: '15px',
          padding: '5px',
          marginTop: "1px",
          display: 'inline-flex',
          marginBottom: "1px"
        }}
      >
        <ToggleButtonGroup
          color='standard'
          value={selectedTab}
          exclusive
          onChange={handleTabChange}
        // aria-label="analytics tabs"
        // TabIndicatorProps={{ style: { display: 'none' } }}   
        >
          {[
            { value: 'analyticsOverview', label: 'Overview' },
            { value: 'taskByDate', label: 'Task By Date' },
            { value: 'taskByExperts', label: 'Task By Experts' },
            { value: 'vendorAnalytics', label: 'Vendor Analytics' },
          ].map((tab) => {
            return (
              <ToggleButton
                key={tab.value}
                value={tab.value}
                sx={{
                  border: 'none !important',
                  fontFamily: 'Montserrat !important',
                  fontSize: '14px !important',
                  fontWeight: '600 !important',
                  lineHeight: '24px !important',
                  color: selectedTab === tab.value ? '#fff !important' : '#6C6C6C !important',
                  borderRadius: '15px !important',
                  backgroundColor: selectedTab === tab.value ? '#03BBD2 !important' : '#fff !important',
                  textTransform: 'none !important',
                }}                
              >
                {tab.label}
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
      </div>

      <Box sx={{ mt: 1}}>
        {selectedTab === 'analyticsOverview' && <AnalyticsOverview />}
        {selectedTab === 'taskByDate' && <TaskByDate />}
        {selectedTab === 'taskByExperts' && <TaskByExperts />}
        {selectedTab === 'vendorAnalytics' && <VendorAnalytics />}
      </Box>




      {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4%' }}>
        {Object.keys(analytics).map((key:string, index) => {
          return (
            <Paper
              elevation={3}
              key={key}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: backgrounds[(index+1)%backgrounds.length],
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',  
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              { getAnalyticsStatus == STATUS.PENDING ? <CircularProgress size={17} /> : <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '24px', fontWeight: 'bold' }}>
                  {String(analytics[key as keyof typeof analytics])}
                </p>
                <h3 style={{ margin: 0, fontSize: '16px', fontWeight: '500' }}>{toTitleCase(key.replaceAll('_', ' '))}</h3>
              </div>}
            </Paper>
          )
        })}
      </div> */}

      {/* <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>Tasks by Date</Typography>
      <TaskCompletedList />
      {association_type === 'COMPANY' &&
        (
          <>
            <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>Vendor Analytics</Typography>
            <VendorAnalyticsListTable />
          </>
        )
      } */}

      {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
        <Typography
          sx={{
            marginTop: '40px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700,
            fontSize: '20px', lineHeight: '14px', marginBottom: '20px'
          }}
        >
          Tasks by Experts
        </Typography>
        <IconButton
          style={{
            height: '40px',
            borderRadius: '10px',
            padding: '8px 6px 8px 6px',
            marginTop: '10px',
            color: 'white',
            marginRight: '20px',
            backgroundColor: downloadAnalyticsByExpertStatus === STATUS.PENDING ? '#dfdfdf' : '#3D85C6',
          }}
          //onClick={handleExpertAnalyticsDownload}
          onClick={handleMenuClick}
          disabled={downloadAnalyticsByExpertStatus == STATUS.PENDING}
          size='small'
        >
          {downloadAnalyticsByExpertStatus == STATUS.PENDING ? <CircularProgress size={25} /> : <DownloadIcon />}
        </IconButton>
        <Menu
        anchorEl={anchorEl}  
        open={Boolean(anchorEl)}  
        onClose={handleMenuClose}  
      >
        <MenuItem onClick={handleDownloadForTask}>Download for Task</MenuItem>
        <MenuItem onClick={handleDownloadForReview}>Download for Review</MenuItem>
      </Menu>
      </div> */}

      {/* <div style={{display: "flex", gap: "45px"}}>
        <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
        <div style={{marginTop: "-10px"}}>
          <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
      </div> */}

      {/* <ToggleButtonGroup
        color="standard"
        value={tableSwitch}
        exclusive
        onChange={handleChangeToggle}
      >
        <ToggleButton
          value="TASK"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: tableSwitch === 'TASK' ? '#03BBD2' : '#6C6C6C',
            borderBottom: tableSwitch === 'TASK' ? '3px solid #03BBD2' : 'none',
            backgroundColor: 'inherit',
            textTransform: 'none'
          }}
        >
          Tasker
        </ToggleButton>
        <ToggleButton
          value="REVIEW"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: tableSwitch === 'REVIEW' ? '#03BBD2' : '#6C6C6C',
            borderBottom: tableSwitch === 'REVIEW' ? '3px solid #03BBD2' : 'none',
            backgroundColor: 'inherit',
            textTransform: 'none'
          }}
        >
          Reviewer
        </ToggleButton>
      </ToggleButtonGroup>
      <AnalyticsListTable tableSwitch={tableSwitch} />
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      /> */}

    </div>
  )
}

export default AnalyticsMain
