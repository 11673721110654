import React from 'react';
import { TableRow } from '@mui/material';
import { TableCellBody } from '../table/tableCell';

interface TableRowItemProps {
  row: any;
}

const TableRowItem: React.FC<TableRowItemProps> = ({ row }) => {
  return (
    <TableRow
      key={row.id}
    >
      <TableCellBody>{row.date ? row.date : '-'}</TableCellBody> 
      <TableCellBody>{row.completed_task_count ? row.completed_task_count : '-'}</TableCellBody>
      {/* <TableCellBody>{row.total_tasks_allocated ? row.total_tasks_allocated : '-'}</TableCellBody>
      <TableCellBody>{row.not_started_tasks_count ? row.not_started_tasks_count : '-'}</TableCellBody>
      <TableCellBody>{row.active_task_count ? row.active_task_count : '-'}</TableCellBody>
      <TableCellBody>{row.tasks_completed_count ? row.tasks_completed_count : '-'}</TableCellBody>
      <TableCellBody>{row.avg_task_completeing_per_day ? row.avg_task_completeing_per_day : '-'}</TableCellBody> */}
    </TableRow>
  );
};

export default TableRowItem;
