import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { Autocomplete, CircularProgress, Divider, FormControl, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import Linechart from './Linechart';
import { getExpertAnalytics } from '../../redux/slices/analytics/experts';
import DashboardHeading from '../../components/dashboard/dashboardHeading';
import { getMetricsAction, getProjectWiseAnalyticsAction, getPseudoNameWiseAnalyticsAction, getTaskWiseAnalyticsAction, getVendorWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard';
import { STATUS } from '../../utils/status';
import ChartForPseudoname from './ChartForPseudoname';
import ActiveProjectsList from '../../components/Active Project List/ActiveProjectsList';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';
import debounce from 'lodash.debounce';
import ActiveProjectVendorList from '../../components/Active Vendor List/ActiveProjectVendorList';
import Overview from '../../components/AllDashboardTabs/Overview';
import TaskingOverview from '../../components/AllDashboardTabs/TaskingOverview';
import ReviewingOverview from '../../components/AllDashboardTabs/ReviewingOverview';
import ActiveProjectsOverview from '../../components/AllDashboardTabs/ActiveProjectsOverview';
// import ActiveVendorsOverview from '../../components/AllDashboardTabs';


const DashboardMain = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const [tableSwitch, setTableSwitch] = useState("TASK")
  const {
    metrics, getMetricsStatus,
    pseudoNameWiseAnalytics, getTaskWiseAnalytics, getTaskWiseAnalyticsStatus, getPseudoNameWiseAnalyticsStatus,
    getProjectWiseAnalyticsStatus, projectWiseAnalytics, getVendorWiseAnalytics, getVendorWiseAnalyticsActionStatus,
  } = useSelector((state: RootState) => state.dashboard)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get('tab') || 'overview';
  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    setSearchParams({ tab: newValue });
  };

  useEffect(() => {
    const urlTab = searchParams.get("tab");
    if(urlTab && urlTab !== selectedTab){
      setSelectedTab(urlTab)
    }
  },[searchParams])

  const handleChangeToggle = (event: any, table: string,) => {
    if (table !== null) {
      setTableSwitch(table)
      dispatch(getExpertAnalytics({ project_id: id, type: event.target.value, association_type: "Admin" }))
    }
  };

  // useEffect(() => {
  //   // dispatch(getAnalytics({ project_id: "", type: "all_project" }))
  //   dispatch(getMetricsAction({ association_type: "COMPANY" }))
  //   dispatch(getPseudoNameWiseAnalyticsAction())
  //   dispatch(getProjectWiseAnalyticsAction({ association_type: "COMPANY" }))
  //   dispatch(getTaskWiseAnalyticsAction({ allocation_type: "REVIEW" }));
  //   dispatch(getVendorWiseAnalyticsAction({ page: currentPage, pageSize: itemsPerPage as number, association_type: "VENDOR" }))

  // }, [dispatch])



  const handleChangeRowsPerPageActiveProject = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    dispatch(getProjectWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "COMPANY" }));
  };
  const handleChangeRowsPerPageActiveVendors = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1)
    dispatch(getVendorWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "VENDOR" }))
  }

  const handleOnChangeActiveProject = (value: number) => {
    setItemsPerPage(value)
  }
  const handleOnChangeActiveVendors = (value: number) => {
    setItemsPerPage(value)
  }


  return (

    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <DashboardHeading />
      </Box>
      <Divider />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '1rem' }}>
      
      <Paper
        sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1rem',
          mb: '1rem',
          boxShadow: 2,
        }}
      >
                
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="user tabs"
          sx={{ mb: 2 }}
        >
          <Tab value="overview" label={<span style={{textTransform: "none"}}>Overview</span>} />
          <Tab value="taskingOverview" label={<span style={{textTransform: "none"}}>Tasking</span>} />
          <Tab value="reviewOverview" label={<span style={{textTransform: "none"}}>Review</span>} />
          <Tab value="activeProjectOverview" label={<span style={{textTransform: "none"}}>Active Project</span>} />
          <Tab value="activeVendorOverview" label={<span style={{textTransform: "none"}}>Active Vendor</span>} />
        </Tabs>
        

        {selectedTab === 'overview' && (<Overview />)}
        {selectedTab === 'taskingOverview' && <TaskingOverview />}
        {selectedTab === 'activeProjectOverview' && <ActiveProjectsOverview />}
        {selectedTab === 'reviewOverview' && <ReviewingOverview />}
        {selectedTab === "activeVendorOverview" && <ActiveProjectVendorList />}
        
      </Paper>
    </Box>
    </div>
  );
};


interface PaginationEntriesProps {
  itemsPerPage: number;
  onChangeRowsPerPage: any;
  handleOnChange?: any
}


export const ActiveProjectPaginationEntries: React.FC<PaginationEntriesProps> = ({ itemsPerPage, onChangeRowsPerPage, handleOnChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDebounce, setIsDebounce] = useState(false)
  const data = [20, 50, 100, 500]

  const debouncedResults = useMemo(() => {
    setIsDebounce(true)
    return debounce((e) => onChangeRowsPerPage(e), 500)

  }, [onChangeRowsPerPage]);

  useEffect(() => {
    return () => {
      setIsDebounce(false)
      debouncedResults.cancel();
    };
  });


  return (
    <div style={{ display: 'flex', marginRight: '20px', alignItems: 'center' }}>
      <span style={{ paddingTop: '0px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D', marginBottom: '0px' }}>Show</span>
      <FormControl sx={{ m: 1, minWidth: 10 }}>

        <Autocomplete
          // disablePortal
          id="combo-box-demo"
          options={data}
          value={itemsPerPage}
          disabled={isDebounce}
          sx={{ width: 110 }}
          size='small'
          renderInput={(params: any) => (
            <TextField
              type='number'
              {...params}
              // onChange={(e:any) =>  debouncedResults(e.target.value)}
              onChange={(e: any) => debouncedResults(e.target.value)}

              sx={{
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                }
              }}
            />
          )}
          onChange={(e: any, newValue: any) => debouncedResults(newValue)}

        // open={data.includes(itemsPerPage)}
        />
      </FormControl>

      <span style={{ paddingTop: '0px', fontSize: '12px', lineHeight: '18px', fontWeight: '600', color: '#10383D', marginBottom: '0px' }}>Rows</span>
    </div>
  )
}

export default DashboardMain;
