import { CircularProgress, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from '../../redux/store/appStore'
import { STATUS } from '../../utils/status'
import { getAnalytics } from '../../redux/slices/analytics'
import { setDownloadAnalyticsByExpertStatus } from '../../redux/slices/analytics/experts'


function toTitleCase(str: string) {
  return str.replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const AnalyticsOverview = () => {
  const analytics = useSelector((state: RootState) => state.analytics.analytics.data)
  const getAnalyticsStatus = useSelector((state:RootState) => state.analytics.getStatus)
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const [tableSwitch, setTableSwitch] = useState("TASK")
  const association_type = useSelector((state: RootState) => state.projects.association_type);
  const { downloadAnalyticsByExpertStatus, analyticsExpertDownload } = useSelector((state: RootState) => state.analyticsExpert)
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

//   const handleMenuClick = (event: any) => {
//     setAnchorEl(event.currentTarget); 
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);  
//   };

//   const handleDownloadForTask = () => {
//     dispatch(getExpertAnalyticsDownload({
//       project_id: id,
//       type: 'TASK',
//       association_type: association_type,
//       download: true
//     }))
//     handleMenuClose();
//   };

//   const handleDownloadForReview = () => {
//     dispatch(getExpertAnalyticsDownload({
//       project_id: id,
//       type: 'REVIEW',
//       association_type: association_type,
//       download: true
//     }))
//     handleMenuClose();
//   };

//   const handleChangeToggle = (event: any, table: string,) => {
//     if (table !== null) {
//       setTableSwitch(table);
//       // navigate(`/project/${id}?tab=${newAlignment}`);
//       dispatch(getExpertAnalytics({ project_id: id, type: event.target.value, association_type: association_type }))
//     }
//   };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  useEffect(() => {
    dispatch(getAnalytics({ project_id: id, association_type: association_type }))
  }, [dispatch, id])

  useEffect(() => {
    if (downloadAnalyticsByExpertStatus === STATUS.SUCESS) {
      if (typeof analyticsExpertDownload == "string") {
        window.open(analyticsExpertDownload, '_blank')
      }
    }

    if (downloadAnalyticsByExpertStatus == STATUS.FAILED) {
      setSnackbarMessage('Couldn\'t Download File. Please Try Again.')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }

    return () => {
      if (downloadAnalyticsByExpertStatus == STATUS.SUCESS || downloadAnalyticsByExpertStatus == STATUS.FAILED) {
        dispatch(setDownloadAnalyticsByExpertStatus(STATUS.NOT_STARTED))
      }

    }
  }, [downloadAnalyticsByExpertStatus])

  let backgrounds = [
    'linear-gradient(45deg, #42a5f5, #478ed1)',
    'linear-gradient(45deg, #ffa726, #fb8c00)',
    'linear-gradient(45deg, #66bb6a, #4caf50)'
  ]

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {Object.keys(analytics).map((key:string, index) => {
          return (
            <Paper
              elevation={3}
              key={key}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: backgrounds[(index+1)%backgrounds.length],
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              { getAnalyticsStatus == STATUS.PENDING ? <CircularProgress size={17} /> : <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '24px', fontWeight: 'bold' }}>
                  {String(analytics[key as keyof typeof analytics])}
                </p>
                <h3 style={{ margin: 0, fontSize: '16px', fontWeight: '500' }}>{toTitleCase(key.replaceAll('_', ' '))}</h3>
              </div>}
            </Paper>
          )
        })}
      </div>
    </>
  )
}

export default AnalyticsOverview