import { CircularProgress, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { STATUS } from '../../utils/status'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store/appStore'
import { useParams } from 'react-router-dom'
import { getMetricsAction } from '../../redux/slices/dashboard/dashboard'

const Overview = () => {
    const { id } = useParams()
    const dispatch = useDispatch<any>()
    const [tableSwitch, setTableSwitch] = useState("TASK")
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState(1);
  
  
    // const handleChangeToggle = (event: any, table: string,) => {
    //   if (table !== null) {
    //     setTableSwitch(table);
    //     dispatch(getExpertAnalytics({ project_id: id, type: event.target.value, association_type: "Admin" }))
    //   }
    // };
  
    useEffect(() => {
      // dispatch(getAnalytics({ project_id: "", type: "all_project" }))
      dispatch(getMetricsAction({ association_type: "COMPANY" }))
  
    }, [dispatch])
  
  
  
    // const handleChangeRowsPerPageActiveProject = (value: number) => {
    //   setItemsPerPage(value);
    //   setCurrentPage(1);
    //   dispatch(getProjectWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "COMPANY" }));
    // };
    // const handleChangeRowsPerPageActiveVendors = (value: number) => {
    //   setItemsPerPage(value);
    //   setCurrentPage(1)
    //   dispatch(getVendorWiseAnalyticsAction({ page: 1, pageSize: value as number, association_type: "VENDOR" }))
    // }
  
    const handleOnChangeActiveProject = (value: number) => {
      setItemsPerPage(value)
    }
    const handleOnChangeActiveVendors = (value: number) => {
      setItemsPerPage(value)
    }
  
    const {metrics, getMetricsStatus} = useSelector((state: RootState) => state.dashboard)
    
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: "wrap", gap: "20px", marginBottom: '4%' }}>

                <Paper
                    elevation={3}
                    style={{
                        marginRight: '20px',
                        width: '22%',
                        height: '100px',
                        padding: '10px',
                        background: '#6A9C89',
                        color: '#fff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                            {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                            {
                                getMetricsStatus === STATUS.PENDING ?
                                    <CircularProgress size={15} />
                                    : getMetricsStatus === STATUS.SUCESS ?
                                        metrics.data[0].active_projects_count
                                        : 0
                            }
                        </p>
                        <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Total Active Projects</h3>
                    </div>
                </Paper>

                <Paper
                    elevation={3}
                    style={{
                        marginRight: '20px',
                        width: '22%',
                        height: '100px',
                        padding: '10px',
                        background: '#173B45',
                        color: '#fff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                            {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                            {
                                getMetricsStatus === STATUS.PENDING ?
                                    <CircularProgress size={15} />
                                    : getMetricsStatus === STATUS.SUCESS ?
                                        metrics.data[0].total_tasks
                                        : 0
                            }
                        </p>
                        <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Total Task Added</h3>
                    </div>
                </Paper>

                <Paper
                    elevation={3}
                    style={{
                        marginRight: '20px',
                        width: '22%',
                        height: '100px',
                        padding: '10px',
                        background: '#ffa726',
                        color: '#fff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                            {/* {analytics && analytics.total_tasks_allocated ? analytics.total_tasks_allocated : '0'} */}
                            {
                                getMetricsStatus === STATUS.PENDING ?
                                    <CircularProgress size={15} />
                                    : getMetricsStatus === STATUS.SUCESS ?
                                        metrics.data[0].total_tasks_allocated
                                        : 0
                            }
                        </p>
                        <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Total Task Allocated</h3>

                    </div>
                </Paper>

                <Paper
                    elevation={3}
                    style={{
                        marginRight: '20px',
                        width: '22%',
                        height: '100px',
                        padding: '10px',
                        background: '#A04747',
                        color: '#fff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                            {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                            {
                                getMetricsStatus === STATUS.PENDING ?
                                    <CircularProgress size={15} />
                                    : getMetricsStatus === STATUS.SUCESS ?
                                        metrics.data[0].not_started_tasks_count
                                        : 0
                            }
                        </p>
                        <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Task Not Started</h3>
                    </div>
                </Paper>

                <Paper
                    elevation={3}
                    style={{
                        marginRight: '20px',
                        width: '22%',
                        height: '100px',
                        padding: '10px',
                        background: '#914F1E',
                        color: '#fff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                            {/* {analytics && analytics.total_tasks_completed ? analytics.total_tasks_completed : '0'} */}
                            {
                                getMetricsStatus === STATUS.PENDING ?
                                    <CircularProgress size={15} />
                                    : getMetricsStatus === STATUS.SUCESS ?
                                        metrics.data[0].completed_tasks_count
                                        : 0
                            }
                        </p>
                        <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Total Allocated Task Completed</h3>
                    </div>
                </Paper>


                <Paper
                    elevation={3}
                    style={{
                        marginRight: '20px',
                        width: '22%',
                        height: '100px',
                        padding: '10px',
                        background: '#41B3A2',
                        color: '#fff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                            {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                            {
                                getMetricsStatus === STATUS.PENDING ?
                                    <CircularProgress size={15} />
                                    : getMetricsStatus === STATUS.SUCESS ?
                                        metrics.data[0].active_task_count
                                        : 0
                            }
                        </p>
                        <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Active Task</h3>
                    </div>
                </Paper>

                <Paper
                    elevation={3}
                    style={{
                        marginRight: '20px',
                        width: '22%',
                        height: '100px',
                        padding: '10px',
                        background: '#674188',
                        color: '#fff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                            {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                            {
                                getMetricsStatus === STATUS.PENDING ?
                                    <CircularProgress size={15} />
                                    : getMetricsStatus === STATUS.SUCESS ?
                                        metrics.data[0].avg_task_completeing_per_day
                                        : 0
                            }
                        </p>
                        <h3 style={{ margin: "-5px", fontSize: '14px', fontWeight: '500' }}>Avg Daily Task Completion</h3>
                    </div>
                </Paper>

                <Paper
                    elevation={3}
                    style={{
                        marginRight: '20px',
                        width: '22%',
                        height: '100px',
                        padding: '10px',
                        background: '#C75B7A',
                        color: '#fff',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                            {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                            {
                                getMetricsStatus === STATUS.PENDING ?
                                    <CircularProgress size={15} />
                                    : getMetricsStatus === STATUS.SUCESS ?
                                        metrics.data[0].avg_time_taken_to_complete
                                        : 0
                            }
                        </p>
                        <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Avg Tasking Time</h3>
                    </div>
                </Paper>

            </div>
        </>
    )
}

export default Overview