import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AnalyticsPayload } from '../../interfaces/interface';

interface dashboardState {

  metrics: {
    error?: string | undefined,
    data: {
      active_projects_count: number,
      total_tasks: number,
      total_tasks_allocated: number,
      completed_tasks_count: number,
      not_started_tasks_count: number,
      active_task_count: number,
      avg_time_taken_to_complete: number,
      avg_task_completeing_per_day: number
    }[],
    message?: string
  };
  getMetricsStatus: STATUS,

  error?: string | null;

  projectWiseAnalytics: {
    error?: string | undefined,
    message?: string,
    data: {
      project_name: string,
      total_tasks: number,
      total_tasks_allocated: number,
      tasks_completed_count: number,
      not_started_tasks_count: number,
      active_task_count: number,
      time_taken_to_completed: number,
      avg_task_completeing_per_day: number
    }[],
  },
  getProjectWiseAnalyticsStatus: string,

  getPseudoNameWiseAnalyticsStatus: string,
  pseudoNameWiseAnalytics: {
    message?: string,
    error?: string,
    data: {
      pseudo_name: string | undefined,
      date: string,
      task_completed_count: number,
      tasker_count: number
    }[]
  },
  getTaskWiseAnalyticsStatus: string,
  getTaskWiseAnalytics: {
    message?: string,
    error?: string,
    data: {
      pseudo_name: string | undefined,
      date: string,
      task_completed_count: number,
      tasker_count: number
    }[]
  }
  getVendorWiseAnalytics: {
    error?: string | undefined,
    message?: string,
    data: {
      project_name: string,
      total_tasks: number,
      total_tasks_allocated: number,
      tasks_completed_count: number,
      not_started_tasks_count: number,
      active_task_count: number,
      time_taken_to_completed: number,
      avg_task_completeing_per_day: number
    }[],
  },
  getVendorWiseAnalyticsActionStatus: string
}



const initialState: dashboardState = {
  metrics: { data: [] },
  getMetricsStatus: STATUS.NOT_STARTED,
  projectWiseAnalytics: { data: [] },
  getProjectWiseAnalyticsStatus: STATUS.NOT_STARTED,
  getPseudoNameWiseAnalyticsStatus: STATUS.NOT_STARTED,
  pseudoNameWiseAnalytics: {
    data: []
  },
  getTaskWiseAnalytics: {
    data: []
  },
  getTaskWiseAnalyticsStatus: STATUS.NOT_STARTED,
  getVendorWiseAnalyticsActionStatus: STATUS.NOT_STARTED,
  getVendorWiseAnalytics: {
    data:[]
  }
};


export const getMetricsAction = createAsyncThunk(
  'get/metrics',
  async (payload: AnalyticsPayload = {}, thunkAPI) => {
    const { association_type } = payload;
    const queryString = new URLSearchParams({
      ...(association_type && { association_type }),
    }).toString();
    const response = await api.get(`/company/analytics/metrics?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getProjectWiseAnalyticsAction = createAsyncThunk(
  'get/project/wise/analytics',
  async (payload: AnalyticsPayload = {}, thunkAPI) => {
    const { association_type, page, pageSize } = payload;
    const queryString = new URLSearchParams({
      ...(association_type && { association_type }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
    }).toString();
    const response = await api.get(`company/analytics/project_wise?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getVendorWiseAnalyticsAction = createAsyncThunk(
  'get/company/analytics/active_projects',
  async (payload: AnalyticsPayload = {}, thunkAPI) => {
    const { association_type, page, pageSize } = payload;
    const queryString = new URLSearchParams({
      ...(association_type && { association_type }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
    }).toString();
    const response = await api.get(`company/analytics/active_projects?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getPseudoNameWiseAnalyticsAction = createAsyncThunk(
  'get/pseudo/name/wise/analytics',
  async (_, thunkAPI) => {
    const response = await api.get('company/analytics/ps_wise');
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getTaskWiseAnalyticsAction = createAsyncThunk(
  'get/task/wise/analytics',
  async (payload: AnalyticsPayload = {}, thunkAPI) => {
    const { allocation_type } = payload;
    const queryString = new URLSearchParams({
      ...(allocation_type && { allocation_type }),
    }).toString();
    const response = await api.get(`company/analytics/ps_wise?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);


const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMetricsAction.pending, (state, action) => {
        state.getMetricsStatus = STATUS.PENDING
      })
      .addCase(getMetricsAction.fulfilled, (state, action) => {
        state.metrics = action.payload;
        state.getMetricsStatus = STATUS.SUCESS;
      })
      .addCase(getMetricsAction.rejected, (state, action) => {
        state.getMetricsStatus = STATUS.FAILED;
      })
      .addCase(getProjectWiseAnalyticsAction.pending, (state, action) => {
        state.getProjectWiseAnalyticsStatus = STATUS.PENDING
      })
      .addCase(getProjectWiseAnalyticsAction.fulfilled, (state, action) => {
        state.projectWiseAnalytics = action.payload;
        state.getProjectWiseAnalyticsStatus = STATUS.SUCESS;
      })
      .addCase(getProjectWiseAnalyticsAction.rejected, (state, action) => {
        state.getProjectWiseAnalyticsStatus = STATUS.FAILED;
      })
      .addCase(getPseudoNameWiseAnalyticsAction.pending, (state, action) => {
        state.getPseudoNameWiseAnalyticsStatus = STATUS.PENDING
      })
      .addCase(getPseudoNameWiseAnalyticsAction.fulfilled, (state, action) => {
        state.pseudoNameWiseAnalytics = action.payload;
        state.getPseudoNameWiseAnalyticsStatus = STATUS.SUCESS;
      })
      .addCase(getPseudoNameWiseAnalyticsAction.rejected, (state, action) => {
        state.getPseudoNameWiseAnalyticsStatus = STATUS.FAILED;
      })

      .addCase(getTaskWiseAnalyticsAction.pending, (state, action) => {
        state.getTaskWiseAnalyticsStatus = STATUS.PENDING
      })
      .addCase(getTaskWiseAnalyticsAction.fulfilled, (state, action) => {
        state.getTaskWiseAnalytics = action.payload;
        state.getTaskWiseAnalyticsStatus = STATUS.SUCESS;
      })
      .addCase(getTaskWiseAnalyticsAction.rejected, (state, action) => {
        state.getTaskWiseAnalyticsStatus = STATUS.FAILED;
      })

      .addCase(getVendorWiseAnalyticsAction.pending, (state, action) => {
        state.getVendorWiseAnalyticsActionStatus = STATUS.PENDING
      })
      .addCase(getVendorWiseAnalyticsAction.fulfilled, (state, action) => {
        state.getVendorWiseAnalytics = action.payload.results;
        state.getVendorWiseAnalyticsActionStatus = STATUS.SUCESS;
      })
      .addCase(getVendorWiseAnalyticsAction.rejected, (state, action) => {
        state.getVendorWiseAnalyticsActionStatus = STATUS.FAILED;
      })
  },

});

export default dashboardReducer.reducer;
