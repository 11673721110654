import { ActionReducerMapBuilder, current, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { UploadState } from '../interfaces/interface';
import { createAllocation, createBulkAllocation, createBulkUnallocation, createMetaQC, createUpload, getCompletedTask, getUnallocatedTaskCount, getUpload, updateCompletedTask, updateUpload } from '../slices/upload';

const ExtraReducers = (builder: ActionReducerMapBuilder<UploadState>) => {
  builder
  .addCase(createUpload.pending, (state) => {
    state.createStatus = STATUS.PENDING;
  })
  .addCase(createUpload.fulfilled, (state, action: PayloadAction<any>) => { 
    state.upload = action.payload; 
    state.createStatus = STATUS.SUCESS; 
  })
  .addCase(createUpload.rejected, (state, action) => { 
    state.createStatus = STATUS.FAILED;
  })
  .addCase(createAllocation.pending, (state) => {
    state.createAllocationStatus = STATUS.PENDING;
  })
  .addCase(createAllocation.fulfilled, (state, action: PayloadAction<any>) => {
    if(Object.keys(action.payload).includes('payload')) {
      console.log({action})
      if(Object.keys(action.payload.payload.payload).includes('task_ids')) {
        action.payload.payload.payload.task_ids.map((task_id:any) => {
          const taskIndex = state.upload.results.findIndex((task:any) => task.id === task_id)
          if(action.payload.payload.payload.action === 'assign') {
            if(action.payload.payload.payload.type === "REVIEW") {
              state.upload.results[taskIndex] =  {
                ...state.upload.results[taskIndex],
                review_allocations: [ 
                  ...state.upload.results[taskIndex].review_allocations, 
                  {
                    email:action.payload?.payload?.extraData?.expertDetails?.[0],
                    status: 'NOT STARTED',
                    id: action.payload?.data?.allocations?.successful_allocations_list[0]
                  }
                ]
              }
            }
            else
            {
              state.upload.results[taskIndex] =  {
                ...state.upload.results[taskIndex],
                task_allocations: [ 
                  ...state.upload.results[taskIndex].task_allocations, 
                  {
                    email:action.payload?.payload?.extraData?.expertDetails?.[0] , 
                    // id:action.payload?.payload?.extraData?.expertDetails?.[1] , 
                    status: 'NOT STARTED',
                    id: action.payload?.data?.allocations?.successful_allocations_list[0]
                  }
                ]
              }
            }
          } else if(action.payload.payload.payload.action === 'unassign') {
            if(action.payload.payload.payload.type === "REVIEW") {
              state.upload.results[taskIndex] =  {
                ...state.upload.results[taskIndex],
                review_allocations: []
              }
            }
            else
            {
              state.upload.results[taskIndex] =  {
                ...state.upload.results[taskIndex],
                task_allocations: []
              }
            }
          }
          return task_id;
        })
      }
    }
    // state.upload = action.payload; 
    state.createAllocationStatus = STATUS.SUCESS; 
  })
  .addCase(createAllocation.rejected, (state, action) => { 
    state.createAllocationStatus = STATUS.FAILED;
  })
  .addCase(createBulkAllocation.pending, (state) => {
    state.createBulkAllocationStatus = STATUS.PENDING;
  })
  .addCase(createBulkAllocation.fulfilled, (state, action: PayloadAction<any>) => {
    state.upload = action.payload; 
    state.createBulkAllocationStatus = STATUS.SUCESS; 
  })
  .addCase(createBulkAllocation.rejected, (state, action:PayloadAction<any>) => {
    state.createBulkAllocationMessage = action.payload.error.message 
    state.createBulkAllocationStatus = STATUS.FAILED;
  })  
  .addCase(createBulkUnallocation.pending, (state) => {
    state.createBulkUnallocationStatus = STATUS.PENDING;
  })
  .addCase(createBulkUnallocation.fulfilled, (state, action: PayloadAction<any>) => {
    state.upload = action.payload; 
    state.createBulkUnallocationStatus = STATUS.SUCESS; 
  })
  .addCase(createBulkUnallocation.rejected, (state, action) => { 
    state.createBulkUnallocationStatus = STATUS.FAILED;
  })  
  .addCase(getUpload.pending, (state) => {
    state.getStatus = STATUS.PENDING;
  })
  .addCase(getUpload.fulfilled, (state, action: PayloadAction<any>) => {
    state.upload = action.payload; 
    state.getStatus = STATUS.SUCESS; 
  })
  .addCase(getUpload.rejected, (state, action) => { 
    state.getStatus = STATUS.FAILED;
  })  
  .addCase(getUnallocatedTaskCount.pending, (state) => {
    state.getTaskCountStatus = STATUS.PENDING;
  })
  .addCase(getUnallocatedTaskCount.fulfilled, (state, action: PayloadAction<any>) => {
    state.unallocatedCount = action.payload.count; 
    state.getTaskCountStatus = STATUS.SUCESS; 
  })
  .addCase(getUnallocatedTaskCount.rejected, (state, action) => { 
    state.getTaskCountStatus = STATUS.FAILED;
  })  
  .addCase(updateUpload.pending, (state) => {
    state.updateStatus = STATUS.PENDING;
  })
  .addCase(updateUpload.fulfilled, (state, action: PayloadAction<any>) => {
    const updateUpload = action.payload;
    state.upload.results = state.upload.results.map((upload: any) =>
      upload.id === updateUpload.id ? updateUpload : upload
    );
    state.updateStatus = STATUS.SUCESS;
  })
  .addCase(updateUpload.rejected, (state, action) => {
    state.updateStatus = STATUS.FAILED;
  }) 
  .addCase(createMetaQC.pending, (state) => {
    state.createMetaQCStatus = STATUS.PENDING;
  })
  .addCase(createMetaQC.fulfilled, (state, action) => {
    const currentIndex = state.upload.results.findIndex(el => action.payload.payload.allocation_ids.includes(el.task_allocations[0]?.alloc_id) || action.payload.payload.allocation_ids.includes(el.review_allocations[0]?.alloc_id))
    
    state.upload.results[currentIndex] = {
      ...state.upload.results[currentIndex],
      ...(state.upload.results[currentIndex].review_allocations.length > 0 && {review_allocations: [{
        ...state.upload.results[currentIndex].review_allocations[0],
        meta_qc_status: action.payload.payload.status
      }]}),
      ...(state.upload.results[currentIndex].task_allocations.length > 0 && {task_allocations: [{
        ...state.upload.results[currentIndex].task_allocations[0],
        meta_qc_status: action.payload.payload.status
      }]})
    }

    state.taskCompleted.data.results = state.taskCompleted.data.results.map((el:any) => {
      if(el.tasks.length > 0) {
        return {
          ...el,
          tasks: el.tasks.map((task:any)=> {
            if(action.payload.payload.allocation_ids.includes(task.task_allocation_id)){
              return {
                ...task,
                meta_qc_status: "READY FOR DELIVERY"
              }
            } else {
              return task
            }
          })
        }
      } else {
        return el
      }
    })
    
    state.metaqc = action.payload.data;
    state.createMetaQCStatus = STATUS.SUCESS;
  
  })
  .addCase(createMetaQC.rejected, (state, action) => {
    state.createMetaQCStatus = STATUS.FAILED;
  })
  .addCase(getCompletedTask.pending, (state) => {
    state.getCompletedTaskStatus = STATUS.PENDING;
  })
  .addCase(getCompletedTask.fulfilled, (state, action) => {
    state.getCompletedTaskStatus = STATUS.SUCESS;
    state.taskCompleted = action.payload;
  })
  .addCase(getCompletedTask.rejected, (state, action) => {
    state.getCompletedTaskStatus = STATUS.FAILED;
  })
  .addCase(updateCompletedTask.pending, (state) => {
    state.updateCompletedTaskStatus = STATUS.PENDING;
  })
  .addCase(updateCompletedTask.fulfilled, (state, action) => {
    state.updateCompletedTaskStatus = STATUS.SUCESS;
    const action_id = action.payload.payload.action_id
    const response_id = action.payload.payload.response_id
    const data = action.payload.data.data
    let currentResponseIndex = state.taskCompleted.data.results.findIndex((result:any) => result.tasks[0].responses[0].id === response_id)
    let currentTask = state.taskCompleted.data.results[currentResponseIndex].tasks[0].responses[0].actions.find((action:any) => action.id === action_id)
    let currentTaskIndex = state.taskCompleted.data.results[currentResponseIndex].tasks[0].responses[0].actions.findIndex((action:any) => action.id === action_id)
    currentTask = {
      ...currentTask,
      id: data.id,
      action_value : currentTask.action_value.map((av:any) => {
        return {
          ...av,
          ...data.action_value
        }
      })
    }
    state.taskCompleted.data.results[currentResponseIndex].tasks[0].responses[0].actions[currentTaskIndex] = currentTask
    // state.taskCompleted = action.payload;
  })
  .addCase(updateCompletedTask.rejected, (state, action) => {
    state.updateCompletedTaskStatus = STATUS.FAILED;
  });
};

export default ExtraReducers;
