import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ProjectForm from '../project/projectForm';
import Config from '../config/config';
import ConfigTable from '../configTable/configTable';
import TaskUploadTable from '../uploadTask/taskUploadTable';
import PaymentForm from '../paymentSettings/paymentForm';
import IntegrationForm from '../modalIntegration/IntegrationForm';
import AnalyticsMain from '../analytics/analyticsMain';
import { useDispatch } from 'react-redux';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';
import { AppDispatch } from '../../redux/store/appStore';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';
import UserTabOptions from '../projectUser/UserTabOptions';
import ProjectListHeading from './projectListHeading';
import { STATUS } from '../../utils/status';

const ProjectTab = () => {
  const getStatus = useSelector((state: RootState) => state.projects.getStatus);
  const [alignment, setAlignment] = useState('task');
  const [userSubTab, setUserSubTab] = useState('experts');
  const [showConfigForm, setShowConfigForm] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);
  const association_type = useSelector((state: RootState) => state.projects.association_type);
  const association_type_company = association_type && association_type === 'COMPANY';
  const association_type_vendor = association_type && association_type === 'VENDOR';

  useEffect(() => {
    if (getStatus == STATUS.SUCESS) {
      const params = new URLSearchParams(location.search);
      const tab = params.get('tab') || 'task';
      const userTab = params.get('userTab') || 'expert';
      setAlignment(tab);
      setUserSubTab(userTab);

      if (association_type_vendor) {
        if (params.get('tab') !== null) {
          if (['edit', 'config', 'payment'].includes(params.get('tab') || '')) {
            const url = new URL(window.location.href);
            url.searchParams.set('tab', 'task');
            navigate(`${url.pathname}${url.search}`, { replace: true });
            setAlignment('task');
          }
        }
      }

      if (association_type_company) {
        const fetchPermissionsProjectTrue = Object.keys(fetchPermissions)
          .filter(key => fetchPermissions[key][0] === true && key.startsWith('project_'))
          .map(key => key.replace('project_', '')).map(key => key == 'users' ? 'user' : key == 'details' ? 'edit' : key == 'payments' ? 'payment' : key)

        // const fetchPermissionsProjectFalse = Object.keys(fetchPermissions)
        // .filter(key => fetchPermissions[key][0] === false && key.startsWith('project_'))
        // .map(key => key.replace('project_', '')).map(key => key == 'users' ? 'user' : key == 'details' ? 'edit' : key)


        if (params.get('tab') !== null) {
          if (
            !fetchPermissionsProjectTrue.includes(params.get('tab') || '')
          ) {
            const url = new URL(window.location.href);
            url.searchParams.set('tab', 'task');
            navigate(`${url.pathname}${url.search}`, { replace: true });
            setAlignment('task');
          }
        }
      }
    }
  }, [location.search, getStatus]);

  const handleChangeToggle = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      navigate(`/project/${id}?tab=${newAlignment}`);
    }
  };

  const handleUserSubTabChange = (
    event: React.MouseEvent<HTMLElement>,
    newSubTab: string
  ) => {
    if (newSubTab !== null) {
      setUserSubTab(newSubTab);
      navigate(`/project/${id}?tab=user&userTab=${newSubTab}`);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleAddConfigClick = () => {
    setShowConfigForm(true);
  };


  useEffect(() => {
    if (id) {
      dispatch(getProject({
        project_id: id,
        fields: JSON.stringify(['name', 'description', 'status', 'data_category', 'annotation_method.id', 'annotation_method.value', 'annotation_method.name', 'task_category.id',
          'task_category.value', 'task_category.name', 'task_category.data_category', 'task_category.annotation_method', 'ideal_task_time', 'ideal_review_time',
          'auto_submit_task', 'reviewer_setting', 'review_setting', 'max_user_per_task', 'task_guidelines', 'expert_guidelines', 'reviewer_guidelines', 'split_screen', 'custom_flow', 'is_custom_flow'
        ])
      }));
    }
  }, [id]);

  return (
    <>
      <div style={{ width: '100vw', overflow: 'hidden' }}>
        <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
          <ProjectListHeading />
        </Box>
        <Divider />
        <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
          <Paper sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '8px',
            padding: '1.5rem',
            mb: '2rem',
            boxShadow: 2,
          }}>
            <div
              style={{
                borderBottom: '1px solid',
                borderColor: '  ',
                margin: 2,
                padding: '0px, 24px, 0px, 24px',
              }}
            >
              <ToggleButtonGroup
                color="standard"
                value={alignment}
                exclusive
                onChange={handleChangeToggle}
              >
                {association_type_company && fetchPermissions.project_details?.[0] &&
                  <ToggleButton
                    value="edit"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: alignment === 'edit' ? '#03BBD2' : '#6C6C6C',
                      borderBottom: alignment === 'edit' ? '3px solid #03BBD2' : 'none',
                      backgroundColor: 'inherit',
                      textTransform: 'none'
                    }}
                  >
                    Edit Project
                  </ToggleButton>
                }
                {association_type_company && fetchPermissions.project_analytics?.[0] &&
                  <ToggleButton
                    value="analytics"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: alignment === 'analytics' ? '#03BBD2' : '#6C6C6C',
                      borderBottom: alignment === 'analytics' ? '3px solid #03BBD2' : 'none',
                      backgroundColor: 'inherit',
                      textTransform: 'none'
                    }}
                  >
                    Analytics
                  </ToggleButton>
                }
                {association_type_vendor && fetchPermissions.project_analytics?.[0] &&
                  <ToggleButton
                    value="analytics"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: alignment === 'analytics' ? '#03BBD2' : '#6C6C6C',
                      borderBottom: alignment === 'analytics' ? '3px solid #03BBD2' : 'none',
                      backgroundColor: 'inherit',
                      textTransform: 'none'
                    }}
                  >
                    Analytics
                  </ToggleButton>
                }
                <ToggleButton
                  value="task"
                  style={{
                    border: 'none',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    color: alignment === 'task' ? '#03BBD2' : '#6C6C6C',
                    borderBottom: alignment === 'task' ? '3px solid #03BBD2' : 'none',
                    backgroundColor: 'inherit',
                    textTransform: 'none'
                  }}
                >
                  Task
                </ToggleButton>
                {association_type_company && fetchPermissions.project_config?.[0] &&
                  <ToggleButton
                    value="config"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: alignment === 'config' ? '#03BBD2' : '#6C6C6C',
                      borderBottom: alignment === 'config' ? '3px solid #03BBD2' : 'none',
                      backgroundColor: 'inherit',
                      textTransform: 'none'
                    }}
                  >
                    Config
                  </ToggleButton>
                }
                {association_type_company && fetchPermissions.project_users?.[0] &&
                  <ToggleButton
                    value="user"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: alignment === 'user' ? '#03BBD2' : '#6C6C6C',
                      borderBottom: alignment === 'user' ? '3px solid #03BBD2' : 'none',
                      backgroundColor: 'inherit',
                      textTransform: 'none'
                    }}
                  >
                    Users
                  </ToggleButton>
                }
                {association_type_vendor &&
                  <ToggleButton
                    value="user"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: alignment === 'user' ? '#03BBD2' : '#6C6C6C',
                      borderBottom: alignment === 'user' ? '3px solid #03BBD2' : 'none',
                      backgroundColor: 'inherit',
                      textTransform: 'none'
                    }}
                  >
                    Users
                  </ToggleButton>
                }
                {/* <ToggleButton
                value="access"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'access' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'access' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                API Access
              </ToggleButton> */}
                {association_type_company && fetchPermissions.project_payments?.[0] &&
                  <ToggleButton
                    value="payment"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: alignment === 'payment' ? '#03BBD2' : '#6C6C6C',
                      borderBottom: alignment === 'payment' ? '3px solid #03BBD2' : 'none',
                      backgroundColor: 'inherit',
                      textTransform: 'none'
                    }}
                  >
                    Payment Settings
                  </ToggleButton>
                }
                {/* <ToggleButton
                value="modal"
                style={{
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: alignment === 'modal' ? '#03BBD2' : '#6C6C6C',
                  borderBottom: alignment === 'modal' ? '3px solid #03BBD2' : 'none',
                  backgroundColor: 'inherit',
                  textTransform: 'none'
                }}
              >
                Modal Integration
              </ToggleButton> */}
              </ToggleButtonGroup>
            </div>


            {alignment === 'user' && (
              <ToggleButtonGroup
                color="standard"
                value={userSubTab} 
                exclusive 
                onChange={handleUserSubTabChange}
              >
                <div style={{ border: "1px solid lightgrey", borderRadius: "15px", marginTop: "10px", padding: "5px 5px 5px 5px" }}>
                  <ToggleButton
                    value="expert"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: userSubTab === 'expert' ? '#fff' : '#6C6C6C',
                      borderRadius: "15px",
                      backgroundColor: userSubTab === 'expert' ? '#03BBD2' : '#fff',
                      textTransform: 'none',
                      marginRight: "5px"
                    }}
                  >
                    Experts
                  </ToggleButton>
                  {!association_type_vendor &&
                    <ToggleButton
                      value="vendor"
                      style={{
                        border: 'none',
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        color: userSubTab === 'vendor' ? '#fff' : '#6C6C6C',
                        borderRadius: "15px",
                        backgroundColor: userSubTab === 'vendor' ? '#03BBD2' : '#fff',
                        textTransform: 'none',
                        marginRight: "5px"

                      }}
                    >
                      Vendors
                    </ToggleButton>
                  }
                  <ToggleButton
                    value="admin"
                    style={{
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: userSubTab === 'admin' ? '#fff' : '#6C6C6C',
                      borderRadius: "15px",
                      backgroundColor: userSubTab === 'admin' ? '#03BBD2' : '#fff',
                      textTransform: 'none'
                    }}
                  >
                    Admins
                  </ToggleButton>
                </div>
              </ToggleButtonGroup>
            )}

            {association_type_company && fetchPermissions.project_details?.[0] && alignment === 'edit' && (
              <div style={{ marginTop: '20px' }}>
                <ProjectForm />
              </div>
            )}

            {
              (
                (
                  (
                    association_type_company &&
                    fetchPermissions.project_analytics[0]
                  ) ||
                  (
                    association_type_vendor &&
                    fetchPermissions.project_analytics[0]
                  )
                ) &&
                alignment === 'analytics'
              )
                ?
                <div style={{ marginTop: '20px' }}>
                  <AnalyticsMain />
                </div>
                : <></>
            }

            {alignment === 'task' && (
              <div>
                <TaskUploadTable />
              </div>
            )}

            {association_type_company && fetchPermissions.project_config?.[0] && alignment === 'config' && (
              <div>
                {showConfigForm ? <Config /> : <ConfigTable onAddConfigClick={handleAddConfigClick} />}
              </div>
            )}

            {((association_type_vendor || (association_type_company && fetchPermissions.project_users?.[0])) && alignment === 'user') &&
              <UserTabOptions userTab={userSubTab} />
            }
            {/* {alignment === 'access' && (
            <div>
              <Typography sx={{marginTop:'10px'}}>Project ID: {formattedId}</Typography>
            </div>
          )} */}
            {association_type_company && fetchPermissions.project_payments?.[0] && alignment === 'payment' && (
              <div>
                <PaymentForm />
              </div>
            )}
            {/* {alignment === 'modal' && (
              <div>
                <IntegrationForm />
              </div>
            )} */}
          </Paper>
        </Box>
      </div>
    </>
  );
}

export default ProjectTab;
